@use "sass:color";

@import "~bootstrap/scss/functions";

@import "~bootswatch/dist/flatly/variables";

$primary: #036067;
$correct-highlight: #44ff44;

@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";

@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";

@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/helpers";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/alert";

@import "~bootstrap/scss/utilities/api";

@import "~bootswatch/dist/flatly/bootswatch";
@import "~react-toastify/dist/ReactToastify.css";

body {
  background-color: #282c34;
}

.App {
  min-height: 100vh;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.prompt-image {
  width: 512px;
  margin: 0 auto;
  max-width: 100vw;
  aspect-ratio: 1;
}

.image-layer {
  position: relative;
  width: 100%;
  height: 100%;

  & > img {
    position: absolute;
    max-width: 100%;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

img.overlay-appear {
  animation: fadeIn 1.5s;
}

/* A question with an image prompt and yes / no answer buttons */
.question.yes-no {
  max-width: 512px;
  margin: 0 auto;

  & > img {
    max-width: 100%;
  }
}

$button-gradient-primary: #3ca8d6;
$button-gradient-secondary: #164e66;

button {
  padding: 10px;
  margin: 5px;

  background-image: linear-gradient(
    $button-gradient-primary,
    $button-gradient-secondary
  );
  border-radius: 8px;
  border-width: 0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.05);
  color: #fff;
  cursor: pointer;
  justify-content: center;
  outline: none;
  transition: all 250ms;

  &:hover {
    box-shadow: rgba(0, 1, 0, 0.2) 0 2px 8px;
    opacity: 0.85;
  }

  &:active {
    outline: 0;
  }

  &:disabled {
    background-image: linear-gradient(
      color.scale(
        $button-gradient-primary,
        $lightness: -30%,
        $saturation: -50%
      ),
      color.scale($button-gradient-secondary, $lightness: -30%)
    );
  }

  &.answered.correct {
    box-shadow: 0 0 1px 4px $correct-highlight;
  }

  &.answered.incorrect {
    opacity: 0.4;
  }
}

/* A group of buttons that between them represent the possible answers
 * in a test
 */
.option-button-group {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  & > button {
    flex: 1 1 auto;
  }
}

.multiple-choice {
  display: grid;
  gap: 10px;

  &.two-column {
    grid-template-columns: repeat(2, 1fr);
  }

  &.four-column {
    grid-template-columns: repeat(4, 1fr);
  }

  .question {
    width: 100%;
    cursor: pointer;

    &.answered.correct {
      box-shadow: 0 0 1px 4px $correct-highlight;
    }

    &.answered.incorrect {
      opacity: 0.4;
    }
  }
}

/* Content to be used to impart information to the user, rather than questions. */
.teaching {
  &.word.primary-focus {
    font-size: 150px;

    @include media-breakpoint-down(md) {
      font-size: 50px;
    }

    text-align: center;
  }
}

.illustration-admin {
  img.illustration-sample {
    width: 256px;
  }
}

.card {
  padding: 0.5em;

  &.loading-card {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

// Used for information that will be persistently visible in the UI, but in a
// non-prominent location.
.persistent-status {
  position: fixed;
  right: 1em;
  bottom: 1em;
}

.vocabulary-status {
  min-width: 140px;
}

// Text that is a short (1-2 sentence) piece of information to be delivered
// to the user, e.g. in an error or a loading box.
.message {
  font-size: 200%;
}
